import fr from './fr'


export default (text, lang = 'fr', key = null) => {

	if (lang === 'fr') {
		key = key || text
		if ('undefined' !== typeof fr[key] && '' !== fr[key]) {
			return fr[key]
		}
	}
	return text
}