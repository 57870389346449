export default {
	'Previous': 'Précédent',
	'Next': 'Suivant',
	'RECOVER FASTER': `RÉCUPÉREZ RAPIDEMENT`,
	'REHAB STRONGER': `GAGNEZ EN FORCE`,
	'PERFORM BETTER': `AUGMENTER VOS PERFORMANCES`,
	'KAATSU - The Original BFR': ``,
	'KAATSU Applications': `Pourquoi utiliser KAATSU`,
	'Testimonials': `Témoignages`,
	'What Is KAATSU?': `KAATSU, c'est quoi?`,
	'Recovery': 'Récupération',
	'Rehabilitation': 'Rééducation',
	'Performance': 'Performance',
	'Email address': `Adresse email`,
	'Some of our satisfied customers': `Des clients satisfaits`,
	'ABOUT': `À PROPOS`,
	'TERMS OF USE': `CONDITIONS D'UTILISATION`,
	'PRIVACY': `DONNÉES PERSONNELLES`,
	'Anyone can recover, exercise, or rehabilitate anywhere, anytime.': `La récupération, l'entretien physique et la rééducation pour tout le monde, partout, tout le temps.`,
	'Featured In': `On en parle`,
	'Learn more about KAATSU': `En savoir plus sur KAATSU (USA)`,
	'Subscribe': 'Souscrire',

	// @todo
	'Phone: +1-866-217-3460': 'Téléphone: 0972101004',
	'info@kaatsu.com': 'contact@kaatsu-france.com',
	'Recover faster from travel, training, and competition.': `Récupérez plus rapidement après les déplacements, les entraînements et les compétitions.`,
	'Rehabilitate stronger from injury and surgery.': `Réhabilitez-vous plus vite des blessures ou des opérations.`,
	'Improve your strength, speed, stamina, and mobility.': `Améliorez votre force, votre vitesse, votre endurance et votre mobilité.`,

	// ----- CLÉS CUSTOM
	// texte présentation home
	'home-main-text': `Inventé au Japon et conçu en Californie, KAATSU est le pionnier sur le marché émergent de la restriction du débit sanguin (BFR) qui améliore automatiquement et en toute sécurité la circulation sanguine pour la santé, la forme physique, la rééducation et la récupération. KAATSU est un petit compresseur automatisé accompagné de bandes pneumatiques extensibles qui sont placées autour de vos bras ou jambes. Les bandes se gonflent et se dégonflent dans une séquence brevetée basée sur des algorithmes qui stimulent la circulation, améliorent l'équilibre hormonal et développent le tonus musculaire de manière efficace avec un minimum d'effort. L'équipement KAATSU et les protocoles développés par l’équipe R&D  offrent des performances, une précision et une sécurité inégalées pour les utilisateurs de tous âges, quelque soit le niveaux de forme physique - le matériel Kaatsu peut être utilisé n'importe où et à tout moment afin de vous aider à récupérer plus rapidement, à mieux vous rééduquer et à obtenir de meilleures performances.`,
	// témoignages carousel
	'testimonials-text-1': `«KAATSU change la donne pour les blessés ... il a réduit de moitié mon temps de rééducation."`,
	'testimonials-signature-1': `Stephen McCain, Gymnaste Olympique`,
	'testimonials-text-2': `«L'un de mes meilleurs outils à la maison en ce moment que je couple avec le poids corporel, non seulement parce que je peux maintenir mes muscles mais en plus je peux obtenir une réponse mitochondriale et cellulaire avec moins d'inflammation qu'en soulevant des poids lourds. "`,
	'testimonials-signature-2': `Joseph Mercola, Ostéopathe, Physisiothérapeute`,
	'testimonials-text-3': `«Après un examen attentif, il est clair pour moi que l'équipement KAATSU est le meilleur équipement BFR du marché, car c'est le seul qui vous permet d'activer automatiquement la pression pendant 30 secondes et de l'éteindre pendant cinq secondes. "`,
	'testimonials-signature-3': `Ben Greenfield, Triathlète, Coach, Auteur`,
	'testimonials-text-4': `«J'ai eu un entraînement de haut en bas uniquement avec KAATSU. Cela témoigne de son efficacité non seulement en termes de construction musculaire, mais aussi en termes d'efficacité fonctionnelle. Je pense que ce sera aussi la formation la plus largement bénéfique [formation méthode] - bon pour les personnes de 12 à 80 ans, pour des athlètes d'élite, pour des forces de l'ordre ou des militaires en passant par les parents au foyer. Cela peut s'appliquer sur tous les humains de la planète. "`,
	'testimonials-signature-4': `Bode Miller, Médaillé d'or au JO de Ski`,
	'testimonials-text-5': `«J'avais vraiment mal pour une entorse à l'aine. Je ne pouvais rien soulever ni bouger sans avoir de douleur. J'étais un peu nerveux à l'idée d'utiliser les bandes KAATSU, mais après, j'ai pu me promener sans douleur. J'ai été surpris et je me sens merveilleusement bien maintenant. "`,
	'testimonials-signature-5': `80 ans, retraitée, Mary Chavez`,
	'testimonials-text-6': `«Je suis sous le choc de ce que ressentent mes avant-bras et mes poignets. J'éprouvais une telle douleur sans arrêt. Ma peau, mes muscles, mon tronc, ont tous été aidés en faisant les cycles KAATSU quotidiennement. Je peux tout faire à mon rythme avec les bande KAATSU surtout après une longue journée de travail. "`,
	'testimonials-signature-6': `56 ans esthéticienne, Christina Newman`,
	'testimonials-text-7': `«La raison pour laquelle j'ai acheté le KAATSU était pour la neuropathie. Ma neuropathie est causée par une diminution du flux sanguin vers les jambes et les pieds qui crée des problèmes de mobilité et d'équilibre. Cela m'a surpris que les résultats de la thérapie soient immédiatement perceptibles. J'ai ressenti une plus grande mobilité, une meilleure vitesse et un meilleur équilibre, et j'ai également remarqué une amélioration de mon sommeil la nuit, ce qui permet une plus grande capacité cognitive. L'avantage supplémentaire est que je construis de la masse musculaire avec très peu d'exercices. Lors de mon dernier examen médical, mon médecin a dit que j'avais un fort pouls aux chevilles.`,
	'testimonials-signature-7': `Chilton Hawk, technicien adjoint des animaux de laboratoire`,
	'testimonials-text-8': `«KAATSU est une thérapie remarquable à laquelle j'ai été initié en me remettant de ma blessure subie en Afghanistan. Mes blessures sont assez profondes: je suis hémiplégique du côté gauche de mon corps. Avec KAATSU, j'ai fait de grands progrès depuis et je suis capable ajoud'hui de déambuler avec une canne quad. Je suis redevable de cette immense bénédiction qui m'a aidé à retrouver une certaine indépendance dans ma vie, très affectée en raison de ma blessure au combat. "`,
	'testimonials-signature-8': `Joe Lowrey, 34 ans, militaire`,
	'testimonials-text-9': `«Avant les compétitions de natation, je m'entraîne quotidiennement avec les bandes KAATSU Aqua et je récupére avec KAATSU après chaque séance d'entraînement. Lors des meetings, j'utilise KAATSU après chaque séance pour mieux récupérer d'autant plus que nous n'avions pas accès à un massothérapeute. Avec mon calendrier d'événements et la durée de la compétition, je n'aurais pas pu faire de mon mieux sans KAATSU à mes côtés pour m'aider à récupérer plus rapidement. De plus, en regardant en arrière, je sens que la douleur de course que j'ai ressentie lors de mon entraînement avec les groupes KAATSU Aqua m'a permis de mieux gérer mes efforts et d'établir trois records du monde en juniors.`,
	'testimonials-signature-9': `Michael Andrew, USA Membre de l'équipe nationale de natation."`,
	'testimonials-text-10': `«KAATSU est un entraînement intense. Je ne savais pas que je pouvais ressentir cela si tôt. J'ai été assez surpris. J'avais beaucoup plus de mobilité. Les niveaux de douleur dans mon dos de 7-8 sont descendus à 2-3. Cela m'a permis de surmonter mes maux de dos et de pouvoir faire d'autres exercices. Moins d'une semaine après mon accident, j'ai guéri très rapidement. Le processus de guérison est incroyable. Cela a changé la donne et ça change la vie."`,
	'testimonials-signature-10': `Le major des Marines américains Gamal Awad`,
	'testimonials-text-11': `«KAATSU m'a vraiment aidé à me remettre de certaines blessures graves. Mon mari et moi sommes devenus accro à KAATSU. Je n'aime pas utiliser des poids lourds. Je suis fort, mais maigre, et j'aime faire des entraînements très variés. C'est facile à faire, léger et efficace lorsque je voyage. Je ne fais la promotion que de ce qui fonctionne pour moi et les résultats parlent d’eux-mêmes. Je crois fermement en KAATSU. "`,
	'testimonials-signature-11': `Hawley Bennett, triple olympien`,
	'testimonials-text-12': `«Après ma première session intensive de KAATSU, j'étais extatique de pouvoir utiliser  ma main droite qui me faisait ressentir d'intenses douleurs nerveuses. Tout était presque parti. J'ai appelé tous mes amis à 2 heures du matin pour leur parler de ce truc fabuleux appelé KAATSU, cette méthode extraordinaire et incroyable. Je le recommande à tous ceux qui ont des problèmes physiques. "`,
	'testimonials-signature-12': `Susan Friedman, enseignante à la retraite`,
	// notre histoire carousel
	'our-story-title-1': `Aperçu`,
	'our-story-text-1': `KAATSU - en particulier le mode constant KAATSU - a été envisagé pour la première fois en 1966 par le Dr Yoshiaki Sato à Tokyo. Le cycle KAATSU a ensuite été développé en 1973 comme un moyen de se remettre de blessures et d'augmenter le confort et la facilité d'utilisation. Des études universitaires rigoureuses et des recherches médicales sur KAATSU ont commencé en 1995 à l'Université de Tokyo. Son adoption au Japon a littéralement transformé les effets de l'entraînement sportif traditionnel ainsi que la vitesse de récupération et de rééducation. Après des décennies d'expérimentation et de démonstrations concernant l'amélioration de la circulation sanguine et de l'augmentation de la production hormonale grâce à des protocoles pratiques, KAATSU a totalement emmergé dans le marché du BFR. KAATSU est aujourd'hui le leader mondial du marché des BFR avec un bilan de sécurité inégalé et une adoption généralisée sur les marchés de la performance athlétique, de la rééducation et de la récupération.`,
	'our-story-title-2': `La découverte`,
	'our-story-text-2': `En 1966, les jambes de Yoshiaki Sato se sentaient engourdies alors qu'il était assis sur un sol en tatami dans la posture traditionnelle japonaise ("seiza"). Il s'est rendu compte que sa circulation sanguine était bloquée dans le bas de ses jambes. Ce moment d'inspiration l'a amené à créer le concept original de l'entraînement à la restriction du flux sanguin et à poursuivre une vie d'exploration, de recherche et de découverte sur le terrain.`,
	'our-story-title-3': `Les preuves`,
	'our-story-text-3': `Entre 1966 et 1973, Sato a continué à s'auto-expérimenter quotidiennement sur lui-même. Il a compilé des données détaillées tout en appliquant différentes tailles de tubes de bicyclette, de cordes et de bandes à différentes pressions sur diverses parties de son corps. Il a méthodiquement suivi les types de bandes et de pressions qui étaient sécuritaires et fonctionnaient le mieux.`,
	'our-story-title-4': `Le brevet`,
	'our-story-text-4': `En 1994, Sato a déposé ses premiers brevets au Japon (brevet n ° 2670421), aux États-Unis (brevet n ° 6149618) et en Europe (Royaume-Uni, Allemagne, France, Italie avec 94206403.0) alors qu'il commençait à fabriquer et à commercialiser les premiers bracelets KAATSU au Japon.`,
	'our-story-title-5': `Programme de certification de spécialiste KAATSU`,
	'our-story-text-5': `En 1997, KAATSU à crée le programme d'éducation spécialisée KAATSU au Japon. Il a également commencé la recherche et les tests cliniques à l'hôpital de l'Université de Tokyo au 22nd Century Medical and Research Center. Le programme a été étendu aux États-Unis et en Europe en 2014.`,
	'our-story-title-6': `L'expansion de Kaatsu`,
	'our-story-text-6': `Depuis qu'il a été utilisé par l'équipe olympique d'hiver des États-Unis en 2014, par l'armée américaine ou encore les athlètes aux Jeux olympiques de Rio 2016, KAATSU s'est étendu à 49 pays d'Europe, d'Amérique du Nord, d'Amérique du Sud, d'Asie, d'Océanie et d'Afrique -  mếme les grimpeurs du mont Everest les nageurs de la Manche ainsi que les paraplégiques et toutes les personnes de tous âges et de tous horizons qui souhaitent perdre du poids et gagner du tonus musculaire utilisent Kaatsu.`,
	'our-story-title-7': ``,
	'our-story-text-7': ``,
	'our-story-title-8': ``,
	'our-story-text-8': ``,
}
